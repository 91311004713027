.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}


@font-face {
  font-family: "a3270";
  src: local("a3270"), url("fonts/3270-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-thin";
  src: local("montserrat"), url("fonts/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "montserrat";
  src: local("montserrat"), url("fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "cormorant-unicase";
  src: local("cormorant unicase"), url("fonts/CormorantUnicase-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "cormorant-unicase";
  src: local("cormorant unicase"), url("fonts/CormorantUnicase-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "cormorant";
  src: local("cormorant"), url("fonts/Cormorant-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "cormorant";
  src: local("cormorant"), url("fonts/Cormorant-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "texturina";
  src: local("texturina"), url("fonts/Texturina-VariableFont_opsz,wght.ttf") format("truetype");
}

@font-face {
  font-family: "orbitron";
  src: local("orbitron"), url("fonts/Orbitron-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "logofont";
  src: local("RockSalt"), url("fonts/RockSalt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("fonts/Roboto-Regular.ttf") format("truetype");
}